import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@shopify/polaris';
import { ProjectPhasesContext } from '../../projectPhases/contexts/ProjectPhasesContext';
import { RespondentsContext } from '../contexts/RespondentsContext';
import { PhaseRespondentsContext } from '../../projectPhases/contexts/PhaseRespondentsContext';
import SelectInput from '../../form/components/SelectInput';
import FormInput from '../../form/components/FormInput';
import { Respondent, RespondentFormValues } from '../model';
import axios from '../../utils/axios.utils';
import InformationField from '../../form/components/InformationField';

const CopyToOtherPhaseForm: React.FC = (props) => {
  let { respondentToEdit, setCopyToOtherPhaseModalOpen } = useContext(PhaseRespondentsContext);

  const [projectOptions, setProjectOptions] = React.useState();
  const [phaseOptions, setPhaseOptions] = React.useState();

  const { fetchRespondents } = React.useContext(PhaseRespondentsContext);
  const [formValues, setFormValues] = useState<RespondentFormValues>({});
  const [respondentSubmitting, setRespondentSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setFormValues({
      ...respondentToEdit,
      copied_from_id: respondentToEdit.phase_id,
    });

    axios.get<string, any>(`invitation_opportunities/new.json`).then((response) => {
      setProjectOptions(response?.data?.project_options);

      axios
        .get<string, any>(`invitation_opportunities/new?project_id=${respondentToEdit?.project_id}`)
        .then((response) => {
          setPhaseOptions(response?.data?.phase_options);
        });
    });
  }, [respondentToEdit]);

  useEffect(() => {
    if (respondentToEdit?.copied_to_id) {
      setErrorMessage('This respondent has already been copied');
    } else if (!formValues?.phase_id) {
      setErrorMessage('Phase is a Required Field');
    } else if (parseInt(formValues?.phase_id, 10) === parseInt(respondentToEdit?.phase_id, 10)) {
      setErrorMessage('Cannot copy respondent to the phase it already belongs to.');
    } else {
      setErrorMessage('');
    }
  }, [formValues, respondentToEdit]);

  const handleProjectChange = async (value: string) => {
    setFormValues({ ...formValues, project_id: value });
    await axios.get<string, any>(`invitation_opportunities/new?project_id=${value}`).then((response) => {
      setPhaseOptions(response?.data?.phase_options);
    });
  };

  const handleCopy = async () => {
    setRespondentSubmitting(true);

    await axios.post<string, any>(`respondents/${respondentToEdit?.id}/copy_to_phase.json`, {
      respondent: { ...formValues },
    });
    await fetchRespondents(`phase_id=${respondentToEdit?.phase_id}`);
    setCopyToOtherPhaseModalOpen(false);
  };

  return (
    <div style={{ marginTop: -40 }}>
      <Grid container spacing={4}>
        <Grid item>
          <InformationField label="Respondent Name" value={respondentToEdit?.name} />
        </Grid>
        <Grid item>
          <InformationField label="User ID" value={respondentToEdit?.uuid} />
        </Grid>
        <Grid item>
          <InformationField label="Current Phase" value={respondentToEdit.phase_name} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInput
            label="Project"
            value={formValues?.project_id}
            choices={projectOptions}
            onChange={handleProjectChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Phase"
            choices={phaseOptions}
            value={formValues?.phase_id}
            onChange={(value) => setFormValues({ ...formValues, phase_id: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Original Qualtrics Status"
            value={formValues?.qualtrics_status}
            onChange={(value) => null}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput label="Interview Status" value="No Interview" onChange={(value) => null} disabled />
        </Grid>
      </Grid>

      {!respondentSubmitting && (
        <Button primary onClick={handleCopy} disabled={errorMessage?.length > 0}>
          Copy To Phase
        </Button>
      )}

      <p style={{ color: 'red', marginBottom: 7 }}>{errorMessage}</p>
    </div>
  );
};

export default CopyToOtherPhaseForm;
