import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, Loading } from '@shopify/polaris';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { OpportunitiesContext } from '../contexts/OpportunitiesContext';
import { UserContext } from '../../auth/contexts/UserContext';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';
import ArchiveOpportunityConfirmModal from '../components/ArchiveOpportunityConfirmModal';
import CopyOpportunityConfirmModal from '../components/CopyOpportunityConfirmModal';
import OpportunityImages from '../components/OpportunityImages';

import { Opportunity } from '../model';

import axios from '../../utils/axios.utils';

const OpportunityDetail: FunctionComponent = () => {
  const history = useHistory();
  const { opportunityId } = useParams<Record<string, string>>();

  const { updateOpportunity } = useContext(OpportunitiesContext);
  const { currentUserHasRole } = useContext(UserContext);

  let [archiveOpportunityConfirmModalOpen, setArchiveOpportunityConfirmModalOpen] = useState<boolean>(false);
  let [copyOpportunityConfirmModalOpen, setCopyOpportunityConfirmModalOpen] = useState<boolean>(false);
  let [opportunity, setOpportunity] = useState<Opportunity>();

  const fetchOpportunity = async (opportunity_id: string) => {
    const response = await axios.get<string, any>(`opportunities/${opportunity_id}.json`);
    setOpportunity(response?.data?.result);
  };

  useEffect(() => {
    if (opportunityId) {
      fetchOpportunity(opportunityId);
    }
  }, []);

  const EditOpportunityButton = () => {
    return (
      <Button className="primary-btn" onClick={() => history.push(`/admin/opportunities/${opportunity?.id}/edit`)}>
        Edit
      </Button>
    );
  };

  return opportunity?.id ? (
    <Grid container style={{ padding: '1% 2%' }}>
      <ArchiveOpportunityConfirmModal
        opportunityId={opportunity?.id}
        archiveOpportunityConfirmModalOpen={archiveOpportunityConfirmModalOpen}
        setArchiveOpportunityConfirmModalOpen={setArchiveOpportunityConfirmModalOpen}
      />
      <CopyOpportunityConfirmModal
        opportunityId={opportunity?.id}
        copyOpportunityConfirmModalOpen={copyOpportunityConfirmModalOpen}
        setCopyOpportunityConfirmModalOpen={setCopyOpportunityConfirmModalOpen}
      />
      <AdminPageHeader
        title={opportunity.name}
        backButton={{ label: opportunity?.project_number, link: `/admin/projects/${opportunity?.project_id}` }}
        badge={{ label: opportunity?.status }}
        adminButtons={<EditOpportunityButton />}
      />
      <Card sectioned title="Opportunity Details">
        <Grid container>
          <Grid item xs={4}>
            <Card.Section title="Name">
              <p>{opportunity.name}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Audience">
              <p>{opportunity.audience}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Project Name">
              <p>{opportunity.project_name}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Phase Name">
              <p>{opportunity.phase_name}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Category">
              <p>{opportunity.category}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Topic">
              <p>{opportunity.topic_names}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Conditions">
              <p>{opportunity.diagnosis}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Roles">
              <p>{opportunity.insighter_roles}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Specialties">
              <p>{opportunity.specialties}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Start Date">
              <p>{opportunity.start_date}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="End Date">
              <p>{opportunity.end_date}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Status">
              <p
                style={{
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  fontSize: 16,
                }}
              >
                {opportunity.status}
              </p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Invitation Amount">
              <p>${opportunity.invitation_amount}</p>
            </Card.Section>
          </Grid>
          <Grid item xs={4}>
            <Card.Section title="Automatically Update Status">
              <p>{opportunity?.update_status ? 'Yes' : 'No'}</p>
            </Card.Section>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Card.Section title="Opportunity Overview">
              {/* eslint-disable-next-line */}
              <div dangerouslySetInnerHTML={{ __html: opportunity.description }} />
            </Card.Section>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Card.Section title="Learn More">
              {/* eslint-disable-next-line */}
              <div dangerouslySetInnerHTML={{ __html: opportunity.learn_more }} />
            </Card.Section>
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <Card sectioned title="Screening and Follow-up Information">
          <Grid container>
            <Grid item xs={12}>
              <Card.Section title="Pre-Screener URL">
                <p>{opportunity.screener_url}</p>
              </Card.Section>
            </Grid>
            {opportunity.follow_up_failure_message && (
              <Grid item xs={12}>
                <Card.Section title="Screening Failure Message">
                  <p>{opportunity.follow_up_failure_message}</p>
                </Card.Section>
              </Grid>
            )}
            {opportunity.follow_up_success_message && (
              <Grid item xs={12}>
                <Card.Section title="Screening Success Message">
                  <p>{opportunity.follow_up_success_message}</p>
                </Card.Section>
              </Grid>
            )}
            {opportunity.follow_up_category && (
              <Grid item xs={4}>
                <Card.Section title="Follow-up Category">
                  <p>{opportunity.follow_up_category}</p>
                </Card.Section>
              </Grid>
            )}
            {opportunity.follow_up_category && (
              <Grid item xs={8}>
                <Card.Section title={`${opportunity.follow_up_category} Link`}>
                  <p>{opportunity.follow_up_link}</p>
                </Card.Section>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <OpportunityImages opportunity={opportunity} fetchOpportunity={fetchOpportunity} />
      </Grid>
      <Grid item xs={12} spacing={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Grid container spacing={3}>
          <Grid item>
            <Button
              className="primary-btn"
              onClick={() => history.push(`/admin/opportunities/${opportunity?.id}/edit`)}
            >
              Edit
            </Button>
          </Grid>
          <Grid item>
            <Button className="secondary-btn" onClick={() => history.push(`/opportunities/${opportunity?.id}`)}>
              Preview
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="secondary-btn"
              onClick={() => history.push(`/admin/opportunities/${opportunity?.id}/key-details`)}
            >
              Key Details
            </Button>
          </Grid>
          <Grid item>
            <Button className="primary-btn" onClick={() => setCopyOpportunityConfirmModalOpen(true)}>
              Copy
            </Button>
          </Grid>
          {currentUserHasRole('superadmin') && (
            <Grid item>
              {opportunity?.status === 'archived' ? (
                <Button
                  onClick={() => {
                    updateOpportunity(opportunity?.id, { status: 'inactive' });
                  }}
                  className="destructive-btn"
                >
                  Unarchive
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setArchiveOpportunityConfirmModalOpen(true);
                  }}
                  className="destructive-btn"
                >
                  Archive
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Loading />
  );
};

export default OpportunityDetail;
