import React, { FC, useState, Dispatch, SetStateAction, createContext } from 'react';
import axios from '../../utils/axios.utils';
import { Respondent } from '../../respondents/model';

interface PhaseRespondentsContextInterface {
  respondentLoading?: boolean;
  fetchRespondents?: (params: string) => Promise<void>;
  respondents?: Respondent[];
  respondentsCSV?: Respondent[];

  currentPage?: number;
  setCurrentPage?: Dispatch<SetStateAction<number>>;

  respondentToEdit?: Respondent;
  setRespondentToEdit?: Dispatch<SetStateAction<Respondent>>;

  editRespondentDetailsModalOpen?: boolean;
  setEditRespondentDetailsModalOpen?: Dispatch<SetStateAction<boolean>>;

  copyToOtherPhaseModalOpen?: boolean;
  setCopyToOtherPhaseModalOpen?: Dispatch<SetStateAction<boolean>>;

  copyToInvOppModalOpen?: boolean;
  setCopyToInvOppModalOpen?: Dispatch<SetStateAction<boolean>>;

  respondentSurveyListModalOpen?: boolean;
  setRespondentSurveyListModalOpen?: Dispatch<SetStateAction<boolean>>;

  setRespondents?: Dispatch<SetStateAction<Respondent[]>>;
  totalRecords?: number;

  sortParams?: string;
  setSortParams?: Dispatch<SetStateAction<string>>;

  qualtricsStatusFilter?: string;
  setQualtricsStatusFilter?: Dispatch<SetStateAction<string>>;

  interviewStatusFilter?: string;
  setInterviewStatusFilter?: Dispatch<SetStateAction<string>>;

  editAllOpen?: boolean;
  setEditAllOpen?: Dispatch<SetStateAction<boolean>>;

  fetchParams?: string;
  setFetchParams?: Dispatch<SetStateAction<string>>;

  selectedRespondentIds?: any;
  setSelectedRespondentIds?: any;
}

const PhaseRespondentsContext = createContext<PhaseRespondentsContextInterface>({});

const PhaseRespondentsContextConsumer = PhaseRespondentsContext.Consumer;

const PhaseRespondentsContextProvider: FC = ({ children }) => {
  const [respondents, setRespondents] = useState<Respondent[]>([]);
  let [respondentsCSV, setRespondentsCSV] = useState<Respondent[]>([]);
  const [respondentLoading, setRespondentLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortParams, setSortParams] = useState<string>('sort=');
  const [qualtricsStatusFilter, setQualtricsStatusFilter] = useState('');
  const [interviewStatusFilter, setInterviewStatusFilter] = useState('');
  const [fetchParams, setFetchParams] = useState<string>('ransack[query]=');
  const [editAllOpen, setEditAllOpen] = useState(false);
  const [selectedRespondentIds, setSelectedRespondentIds] = useState([]);
  const [respondentToEdit, setRespondentToEdit] = useState<Respondent>();
  const [respondentSurveyListModalOpen, setRespondentSurveyListModalOpen] = useState(false);
  const [editRespondentDetailsModalOpen, setEditRespondentDetailsModalOpen] = useState(false);
  const [copyToOtherPhaseModalOpen, setCopyToOtherPhaseModalOpen] = useState(false);
  const [copyToInvOppModalOpen, setCopyToInvOppModalOpen] = useState(false);

  const fetchRespondents = async (params?: string) => {
    setRespondentLoading(true);

    const response = await axios.get<string, any>(`respondents?${params}`);

    setRespondentLoading(false);
    setTotalRecords(response?.data?.total_records);
    setQualtricsStatusFilter(response?.data?.applied_filters?.qualtrics_status?.value);
    setInterviewStatusFilter(response?.data?.applied_filters?.interview_status?.value);
    setRespondents(response?.data?.result);
    setRespondentsCSV(response?.data?.respondent_csv_data);
  };

  return (
    <PhaseRespondentsContext.Provider
      value={{
        respondentLoading,
        fetchRespondents,
        respondents,
        respondentsCSV,
        respondentToEdit,
        setRespondentToEdit,
        respondentSurveyListModalOpen,
        setRespondentSurveyListModalOpen,
        editRespondentDetailsModalOpen,
        setEditRespondentDetailsModalOpen,
        copyToOtherPhaseModalOpen,
        setCopyToOtherPhaseModalOpen,
        copyToInvOppModalOpen,
        setCopyToInvOppModalOpen,
        totalRecords,
        currentPage,
        setCurrentPage,
        sortParams,
        setSortParams,
        qualtricsStatusFilter,
        interviewStatusFilter,
        setQualtricsStatusFilter,
        setInterviewStatusFilter,
        fetchParams,
        setFetchParams,
        setRespondents,
        editAllOpen,
        setEditAllOpen,
        selectedRespondentIds,
        setSelectedRespondentIds,
      }}
    >
      {children}
    </PhaseRespondentsContext.Provider>
  );
};

export { PhaseRespondentsContextProvider, PhaseRespondentsContextConsumer, PhaseRespondentsContext };
