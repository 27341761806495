import React, { Dispatch, SetStateAction } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { DropzoneArea } from 'material-ui-dropzone';

import axios from '../../../utils/axios.utils';

interface PromptCardProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setMatch: Dispatch<SetStateAction<any>>;
  setSuspiciousOrTerminated: Dispatch<SetStateAction<any>>;
  setNoMatch: Dispatch<SetStateAction<any>>;
  setErrors: Dispatch<SetStateAction<any>>;
  setUsersLoaded: Dispatch<SetStateAction<boolean>>;
}

const PromptCard: React.FC<PromptCardProps> = (props) => {
  const { setLoading, setMatch, setNoMatch, setSuspiciousOrTerminated, setErrors, setUsersLoaded } = props;

  const handleUpload = async (files: File[]) => {
    if (files.length === 0) return; // Guard clause to handle no file uploaded
    setLoading(true);

    const formData = new FormData();
    const attachedFile = files[0];
    formData.append('file', attachedFile, attachedFile?.name);

    try {
      const response = await axios.post(`/list_uploader/upload_list`, formData);
      setMatch(response?.data?.match);
      setSuspiciousOrTerminated(response?.data?.suspicious_or_terminated);
      setNoMatch(response?.data?.no_match);
      setErrors(response?.data?.errors);
    } catch (error) {
      console.error('Upload failed:', error);
      setErrors(['Upload failed. Please try again.']);
    } finally {
      setLoading(false);
      setUsersLoaded(true);
    }
  };

  return (
    <Card style={{ padding: '1% 2%', width: '100%' }}>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ alignItems: 'center' }}>
            <p>
              Drop a <strong>.csv</strong> or <strong>.xlsx</strong> file here.
            </p>
            <p>The list uploader will check which rows are already included in our database.</p>
            <p>
              The columns in your file must be in this order:{' '}
              <strong>First Name, Last Name, Email, Email 2, NPI Number</strong>
            </p>
            <p>
              You may include other columns after <strong>NPI Number</strong>. The rest will be ignored.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} style={{ padding: '5% 20% 5% 20%' }}>
          <DropzoneArea
            acceptedFiles={['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            filesLimit={1}
            dropzoneText="Drag and drop a .csv or .xlsx file here, or click to select"
            onChange={(files) => handleUpload(files)}
            maxFileSize={5000000}
            showAlerts
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default PromptCard;
