import React, { useState, useContext } from 'react';
import { Loading, Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui-bundle';
import DataTable from '../../dataTable/DataTable/DataTable';
import { ProjectsContext } from '../contexts/ProjectsContext';

import projectColumns from '../constants/projectColumns';

const InternalProjectsList: React.FunctionComponent = () => {
  const { projects, fetchProjects, projectLoading, totalRecords, sortParams, setSortParams, filterParams } =
    React.useContext(ProjectsContext);

  const { sortProjects } = useContext(ProjectsContext);
  const [isSorting, setIsSorting] = useState(false);

  const toggleSorting = (): void => {
    if (isSorting) {
      sortProjects($('.rdt_TableBody:first').sortable('serialize'));
      $('.rdt_TableBody:first').sortable('destroy');
    } else {
      $('.rdt_TableBody:first').sortable();
    }

    setIsSorting(!isSorting);
  };

  React.useEffect(() => {
    const internalProjectFilters =
      sessionStorage.getItem('internalProjectFilters') || 'ransack[client_name_eq]=Sommer Consulting';

    fetchProjects(internalProjectFilters);
  }, []);

  const loadingMarkup = projectLoading ? <Loading /> : null;

  const history = useHistory();

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      project_number: 'project_number',
      name: 'name',
      client_name: 'client_name',
      start_date: 'project_timeline_overall_start_date',
      end_date: 'project_timeline_overall_end_date',
      project_manager_name: 'project_manager_last_name',
      recruiter_name: 'recruiter_last_name',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchProjects(`ransack[client_name_eq]=Sommer Consulting&${filterParams}&${sort}`);
  };

  const handlePageChange = (page: number) => {
    fetchProjects(`page=${page}&ransack[client_name_eq]=Sommer Consulting&${filterParams}&${sortParams}`);
  };

  const pageMarkup = (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
        <Button primary={!isSorting} onClick={() => toggleSorting()}>
          {isSorting ? 'Save Order' : 'Sort Projects'}
        </Button>
      </Grid>

      <DataTable
        onRowClicked={(row: Record<string, string>) => history.push(`/admin/projects/${row.id}`)}
        columns={projectColumns()}
        data={projects}
        progressPending={projectLoading}
        striped
        highlightOnHover
        pointerOnHover
        noHeader
        pagination
        paginationPerPage={25}
        paginationRowsPerPageOptions={[25]}
        paginationServer
        paginationTotalRows={totalRecords}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleColumnSort}
      />
    </>
  );

  // ---- Loading ----

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default InternalProjectsList;
