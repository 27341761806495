import React, { FC, useContext } from 'react';

import ConfirmCancelModal from '../../modal/components/MaterialModal';
import CopyToOtherPhaseForm from './CopyToOtherPhaseForm';

import { PhaseRespondentsContext } from '../../projectPhases/contexts/PhaseRespondentsContext';

const CopyToOtherPhaseModal: FC = () => {
  let { copyToOtherPhaseModalOpen, setCopyToOtherPhaseModalOpen } = useContext(PhaseRespondentsContext);

  return (
    <ConfirmCancelModal
      isOpen={copyToOtherPhaseModalOpen}
      onCancel={() => setCopyToOtherPhaseModalOpen(false)}
      title="Copy to Phase"
      height="450px"
    >
      <div style={{ margin: '40px 20px' }}>
        <CopyToOtherPhaseForm />
      </div>
    </ConfirmCancelModal>
  );
};

export default CopyToOtherPhaseModal;
