import React, { FC, useContext } from 'react';

import ConfirmCancelModal from '../../modal/components/MaterialModal';

import { PhaseRespondentsContext } from '../../projectPhases/contexts/PhaseRespondentsContext';

import CopyToInvOppForm from './CopyToInvOppForm';

const CopyToInvitationOpportunityModal: FC = () => {
  let { respondentToEdit, copyToInvOppModalOpen, setCopyToInvOppModalOpen } = useContext(PhaseRespondentsContext);

  return (
    <ConfirmCancelModal
      isOpen={copyToInvOppModalOpen}
      onCancel={() => setCopyToInvOppModalOpen(false)}
      title="Copy to Invitation Opportunity"
      height="450px"
    >
      <div style={{ margin: '40px 20px' }}>
        <CopyToInvOppForm respondent={respondentToEdit} />
      </div>
    </ConfirmCancelModal>
  );
};

export default CopyToInvitationOpportunityModal;
