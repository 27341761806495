import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

interface ErrorCardProps {
  errors: string[];
}

const ErrorCard: React.FC<ErrorCardProps> = (props) => {
  let { errors } = props;

  return (
    <Grid>
      <Card>
        <div style={{ marginBottom: 15 }}>
          <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
            <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
              <h2 className="Polaris-Heading">Errors</h2>
            </div>
          </div>
        </div>

        {errors.map((error) => (
          <div className="Polaris-Stack__Item Polaris-Stack__Item--fill" key={`error-div-${error}`}>
            <p>{error}</p>
          </div>
        ))}
      </Card>
    </Grid>
  );
};

export default ErrorCard;
