import React, { useState, useEffect } from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import useStyles from '../constants/redesignStyles';

const footerLinkData = [
  {
    name: 'Privacy Policy',
    link: '/privacy-policy',
  },
  {
    name: 'Cookie Policy',
    link: '/cookie-policy',
  },
  {
    name: 'Terms of Use',
    link: '/terms-of-use',
  },
  {
    name: 'Pay Portal Terms',
    link: '/pay-portal-terms',
  },
];

const FooterLinks: React.FC = () => {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      if (newWidth !== windowWidth) {
        setWindowWidth(newWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  return (
    <Grid container justifyContent="space-between" style={windowWidth <= 960 ? {} : {}}>
      <Grid item xs={12} md={2}>
        <p className={classes.footerText} style={windowWidth <= 960 ? { textAlign: 'center', marginBottom: 10 } : {}}>
          © 2025 The Insighters
        </p>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <a
              href="https://theinsighters.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className={classes.footerText}
            >
              <p>Privacy Policy</p>
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://theinsighters.com/cookie-policy"
              target="_blank"
              rel="noreferrer"
              className={classes.footerText}
            >
              <p>Cookie Policy</p>
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://theinsighters.com/terms-of-use"
              target="_blank"
              rel="noreferrer"
              className={classes.footerText}
            >
              <p>Terms of Use</p>
            </a>
          </Grid>
          <Grid item>
            <a
              href="https://theinsighters.com/pay-portal-terms"
              target="_blank"
              rel="noreferrer"
              className={classes.footerText}
            >
              <p>Pay Portal Terms</p>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={2} xs={12}>
        <p className={classes.footerText} style={windowWidth <= 960 ? { textAlign: 'center', marginTop: 10 } : {}}>
          Webdesign by Digital Silk
        </p>
      </Grid>
    </Grid>
  );
};

export default FooterLinks;
