import React, { FC, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import InvitationOpportunityForm from '../components/InvitationOpportunityForm';
import { InvitationOpportunitiesContext } from '../contexts/InvitationOpportunitiesContext';

import LoadingSpinner from '../../loading/components/LoadingSpinner';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditInvitationOpportunity: FC = () => {
  const { invitationOpportunity, fetchInvitationOpportunity, invitationOpportunityLoading } =
    useContext(InvitationOpportunitiesContext);
  const { invitationOpportunityId } = useParams<Record<string, string>>();

  useEffect(() => {
    if (invitationOpportunityId) {
      fetchInvitationOpportunity(invitationOpportunityId);
    }
  }, [invitationOpportunityId]);

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title="Edit Invitation Opportunity"
        backButton={{
          label: `Invitation Opportunity Detail`,
          link: `/admin/invitation-opportunities/${invitationOpportunity?.id}`,
        }}
      />
      {invitationOpportunityLoading ? (
        <LoadingSpinner />
      ) : (
        <InvitationOpportunityForm invitationOpportunity={invitationOpportunity} />
      )}
    </Grid>
  );
};

export default EditInvitationOpportunity;
