/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip, IconButton } from '@mui/material';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import Save from '@mui/icons-material/Save';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import RespondentRowMenu from '../../respondents/components/RespondentRowMenu';
import { Respondent } from '../../respondents/model';
import { Phase } from '../model';

import { PhaseRespondentsContext } from '../contexts/PhaseRespondentsContext';
import SelectInput from '../../form/components/SelectInput';
import axios from '../../utils/axios.utils';
import { qualtricsStatuses, qualtricsStatusesWithUpdating } from '../../respondents/constants/qualtricsStatuses';
import getStatusColor from '../../interviews/constants/getStatusColor';

const respondentColumns = (phase: Phase): IDataTableColumn[] => {
  const history = useHistory();
  const { editAllOpen, respondents, setRespondents } = useContext(PhaseRespondentsContext);

  const updateRespondent = async (row, key, value) => {
    const respondentsDup = [...respondents];
    const index = respondents.findIndex((respondent) => respondent.id === row.id);
    const updatedRow = { ...row, qualtrics_status: 'updating', segment: 'Updating...' };
    respondentsDup[index] = updatedRow;
    setRespondents(respondentsDup);

    const response = await axios.put<string, any>(`respondents/${row.id}.json`, {
      respondent: { [key]: value },
    });

    const freshRespondents = [...respondents];
    freshRespondents[index] = response?.data?.result;
    setRespondents(freshRespondents);
  };

  const SegmentFieldWithEditor = ({ row }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [segmentValue, setSegmentValue] = useState('');

    useEffect(() => {
      if (row?.segment) {
        const blocksFromHtml = htmlToDraft(row.segment);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const initialState = EditorState.createWithContent(contentState);
        setEditorState(initialState);
      }
    }, [row.segment]);

    return (
      <form style={{ width: '100%' }} onSubmit={() => updateRespondent(row, 'segment', segmentValue)}>
        <style>
          {`
            .demo-editor {
              min-width: 100%;
            }
          `}
        </style>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={(value) => {
            setEditorState(value);
            setSegmentValue(draftToHtml(convertToRaw(value.getCurrentContent())).replaceAll('<p></p>', '<br />'));
          }}
          toolbarHidden
        />
        <IconButton type="submit">
          <Save />
        </IconButton>
      </form>
    );
  };

  return [
    {
      name: 'User ID',
      selector: 'uuid',
      sortable: true,
      grow: 0.6,
    },
    {
      name: 'Attached Phase',
      selector: 'copied_from_name',
      sortable: true,
      grow: 0.6,
      omit: editAllOpen || phase?.interview_category === 'Virtual Quantitative',
      cell: (row: Respondent) => (
        <>
          {row.copied_to_name && (
            <Tooltip
              onClick={() => history.push(`/admin/phases/${row.copied_to_id}`)}
              title={
                <p style={{ fontSize: '1.4rem', padding: 5, lineHeight: '1.8rem' }}>
                  {`Copied To: ${row.copied_to_name}`}
                </p>
              }
              style={{ fontSize: '2em' }}
            >
              <IconButton>
                <ContentPasteGoIcon style={{ color: '#F48574', fontSize: '0.8em' }} />
              </IconButton>
            </Tooltip>
          )}
          {row.copied_from_name && (
            <Tooltip
              onClick={() => history.push(`/admin/phases/${row.copied_from_id}`)}
              title={
                <p style={{ fontSize: '1.4rem', padding: 5, lineHeight: '1.8rem' }}>
                  {`Copied From: ${row.copied_from_name}`}
                </p>
              }
              style={{ fontSize: '2em' }}
            >
              <IconButton>
                <AssignmentReturnIcon style={{ color: '#72A295', fontSize: '0.8em' }} />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      grow: 0.8,
      cell: (row: Respondent) => (
        <>
          {row.insighter_id ? (
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: '#1D1D1D' }}
                href={`/admin/insighters/${row.insighter_id}`}
              >
                {row.name}
              </a>
            </div>
          ) : (
            <div>{row.name}</div>
          )}
        </>
      ),
    },
    {
      name: 'Applied At',
      selector: 'applied_at',
      sortable: true,
      omit: editAllOpen,
      grow: 0.8,
    },
    {
      name: 'Account Status',
      selector: 'account_status',
      sortable: true,
      grow: 0.7,
      cell: (row: Respondent) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            fontSize: 12,
            color: getStatusColor(row.account_status),
          }}
        >
          {row.account_status}
        </div>
      ),
    },
    {
      name: 'Qualtrics Status',
      selector: 'qualtrics_status',
      sortable: true,
      cell: (row: any) => (
        <>
          {editAllOpen ? (
            <SelectInput
              label={null}
              choices={row.qualtrics_status === 'updating' ? qualtricsStatusesWithUpdating : qualtricsStatuses}
              onChange={(value) => updateRespondent(row, 'qualtrics_status', value)}
              value={row.qualtrics_status}
            />
          ) : (
            <div
              style={{
                fontWeight: 700,
                textTransform: 'uppercase',
                fontSize: 12,
                // color: row.account_status.toLowerCase() === 'insighter' ? 'green' : 'red',
              }}
            >
              {row.qualtrics_status}
            </div>
          )}
        </>
      ),
    },
    {
      name: phase?.interview_category === 'Virtual Quantitative' ? 'Payment Status' : 'Interview Status',
      selector: 'interview_status',
      sortable: true,
      grow: 0.8,
      cell: (row: Respondent) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            fontSize: 12,
            color: 'black',
          }}
        >
          {row.interview_id ? (
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: '#1D1D1D' }}
              href={`/admin/interviews/${row.interview_id}`}
            >
              {row.interview_status}
            </a>
          ) : (
            <p>{row.interview_status}</p>
          )}
        </div>
      ),
    },
    {
      name: 'Homework',
      selector: 'homework_status',
      sortable: true,
      omit: editAllOpen || !phase?.homework,
      cell: (row: Respondent) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            fontSize: 12,
            color: 'black',
          }}
        >
          {row.homework_status}
        </div>
      ),
    },
    {
      name: 'Segment',
      selector: 'segment',
      sortable: true,
      grow: 1.5,
      style: { width: '100%' },
      cell: (row) => (
        <>
          {editAllOpen ? (
            <SegmentFieldWithEditor row={row} />
          ) : (
            <>
              {/* eslint-disable-next-line */}
              <div dangerouslySetInnerHTML={{ __html: row?.segment }} />
            </>
          )}
        </>
      ),
    },
    {
      name: 'Payment Exists',
      selector: 'payment_exists',
      sortable: true,
      grow: 0.5,
      width: '100px',
      cell: (row: Respondent) => <>{row.payment_exists && <MonetizationOn style={{ color: 'green' }} />}</>,
    },
    {
      cell: (respondent: Respondent) => <RespondentRowMenu respondent={respondent} />,
      name: 'ACTIONS',
      allowOverflow: true,
      button: true,
      selector: 'actions',
      omit: editAllOpen,
    },
  ];
};

export default respondentColumns;
