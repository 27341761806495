import React, { useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { CSVLink } from 'react-csv';

import { IDataTableColumn } from '../../../dataTable/DataTable/model';
import DataTableSkeleton from '../../../loading/components/DataTableSkeleton';

import AdminPageHeader from '../../components/AdminPageHeader';
import PromptCard from '../components/PromptCard';
import ListCard from '../components/ListCard';
import ErrorCard from '../components/ErrorCard';

// Previous versions had a table for Rows that were already a lead.
// Plan to re-implement some version of that.
// import leadColumns from '../../../leadGroups/constants/leadColumns';

const singleDataPoint = (row, col) => {
  let thisColor = 'yellow';
  let displayValue = row[`${col}_from_upload`] || '';

  if (row.match === 'no_match' || !row[col] || row[col] === displayValue) {
    thisColor = '';
  }
  return <span style={{ backgroundColor: thisColor }}>{displayValue || ''}</span>;
};

const ListUploaderPage: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [errors, setErrors] = useState([]);
  const [match, setMatch] = useState([]);
  const [noMatch, setNoMatch] = useState([]);
  const [suspiciousOrTerminated, setSuspiciousOrTerminated] = useState([]);
  const [leadSubmitting, setLeadSubmitting] = useState(false);

  const matchColumns: IDataTableColumn<any>[] = [
    { name: 'Match Points', selector: 'match_points', grow: 0.5, sortable: true },
    {
      name: 'Name',
      selector: 'last_name',
      grow: 0.8,
      sortable: true,
      cell: (row) => `${row.first_name} ${row.last_name}`,
    },
    {
      name: 'Emails',
      selector: 'emails',
      grow: 1.2,
      cell: (row) => (
        <div>
          <p>{row.email1}</p>
          <p>{row.email2}</p>
        </div>
      ),
    },
    {
      name: 'NPI #',
      selector: 'npi_number',
      grow: 0.7,
    },
  ];

  // Vertical line between the two halves of the table
  const separatorColumn: IDataTableColumn<any>[] = [
    {
      name: '',
      selector: 'spacer',
      grow: 0.05,
      cell: () => <p style={{ borderLeft: '3px solid green', height: '40px' }} />,
    },
  ];

  const tagColumn: IDataTableColumn<any>[] = [
    {
      name: 'Tags',
      selector: 'tags',
      grow: 0.8,
    },
  ];

  const noMatchColumns: IDataTableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name_from_upload',
      grow: 0.8,
      cell: (row) => (
        <div>
          {singleDataPoint(row, 'first_name')} &nbsp;
          {singleDataPoint(row, 'last_name')}
        </div>
      ),
    },
    {
      name: 'Emails',
      selector: 'emails_from_upload',
      grow: 1.2,
      cell: (row) => (
        <div>
          <p>{singleDataPoint(row, 'email1')}</p>
          <p>{singleDataPoint(row, 'email2')}</p>
        </div>
      ),
    },
    {
      name: 'NPI #',
      selector: 'npi_number_from_upload',
      grow: 0.7,
      cell: (row) => <p>{singleDataPoint(row, 'npi_number')}</p>,
    },
  ];

  const allColumns = matchColumns.concat(separatorColumn).concat(noMatchColumns);
  const colsWithTags = matchColumns.concat(tagColumn);

  const data = 'first_name,last_name,email1,email2, npi number';

  const ClearListButton = () => {
    return usersLoaded ? (
      <Button className="primary-btn" onClick={() => setUsersLoaded(false)}>
        Clear Upload
      </Button>
    ) : (
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
        <CSVLink data={data} filename="Bulk Insighters Template.csv" style={{ textDecoration: 'none' }}>
          <Button className="primary-btn">Download Template</Button>
        </CSVLink>
      </Grid>
    );
  };

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title="List Uploader"
        backButton={{ link: '/admin/tools', label: 'Admin Tools' }}
        adminButtons={[<ClearListButton key="key-needed-for-typscript" />]}
      />
      <Grid item xs={12}>
        {loading && <DataTableSkeleton />}
        {!loading && !usersLoaded && (
          <PromptCard
            setLoading={setLoading}
            setMatch={setMatch}
            setSuspiciousOrTerminated={setSuspiciousOrTerminated}
            setNoMatch={setNoMatch}
            setErrors={setErrors}
            setUsersLoaded={setUsersLoaded}
          />
        )}
        {usersLoaded && errors?.length > 0 && <ErrorCard errors={errors} />}
        {usersLoaded && errors?.length === 0 && (
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <ListCard
                title="Matches"
                columns={allColumns}
                data={match}
                leadSubmitting={leadSubmitting}
                setLeadSubmitting={setLeadSubmitting}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <ListCard
                title="Terminated or Suspicious"
                columns={colsWithTags}
                data={suspiciousOrTerminated}
                leadSubmitting={leadSubmitting}
                setLeadSubmitting={setLeadSubmitting}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <ListCard
                title="No Match"
                columns={noMatchColumns}
                data={noMatch}
                leadSubmitting={leadSubmitting}
                setLeadSubmitting={setLeadSubmitting}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ListUploaderPage;
