import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@mui/material/Card';
import InformationField from '../../form/components/InformationField';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import axios from '../../utils/axios.utils';

const QualtricsMetadataDetail: FC = () => {
  const { phaseId, respondentId } = useParams<Record<string, string>>();

  let [qualtricsMetadata, setQualtricsMetadata] = useState<any>();
  let [metadataError, setMetaDataError] = useState<string>('');

  const fetchQualtricsMetadata = async (respondent_id: string, phase_id: string) => {
    await axios
      .get<string, any>(`qualtrics_metadata/${phase_id}?respondent_id=${respondent_id}`)
      .then((response) => {
        setQualtricsMetadata(response?.data?.result);
      })
      .catch((error) => {
        setMetaDataError(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchQualtricsMetadata(respondentId, phaseId);
  }, []);

  return (
    <Grid container spacing={3} style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title="Qualtrics Metadata"
        backButton={{ label: `Phase ${phaseId}`, link: `/admin/phases/${phaseId}` }}
      />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <InformationField label="Respondent Name" value={qualtricsMetadata?.respondent_name} />
          </Grid>
          <Grid item xs={3}>
            <InformationField label="User ID" value={qualtricsMetadata?.respondent_uuid} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: '1% 2%' }}>
          {metadataError && <p style={{ color: 'red' }}>{metadataError}</p>}
          {qualtricsMetadata?.qualtrics_metadata && (
            <Grid container spacing={3}>
              {Object?.keys(qualtricsMetadata?.qualtrics_metadata)?.map((key, value) => (
                <Grid item xs={6} key={key}>
                  <InformationField label={key} value={qualtricsMetadata?.qualtrics_metadata[key]} />
                </Grid>
              ))}
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default QualtricsMetadataDetail;
