import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SurveyLinkMenuItems from './SurveyLinkMenuItems';
import CustomMaterialMenu from '../../dataTable/rowMenu/CustomMaterialMenu';
import { Respondent } from '../model';
import { UserContext } from '../../auth/contexts/UserContext';
import { PhaseRespondentsContext } from '../../projectPhases/contexts/PhaseRespondentsContext';

interface RespondentRowMenuProps {
  respondent: Respondent;
}

const RespondentRowMenu: React.FC<RespondentRowMenuProps> = (props) => {
  const { respondent } = props;
  const { phaseId } = useParams<Record<string, string>>();
  const { currentUserHasRole } = useContext(UserContext);
  let {
    setRespondentToEdit,
    setEditRespondentDetailsModalOpen,
    setCopyToOtherPhaseModalOpen,
    setCopyToInvOppModalOpen,
  } = useContext(PhaseRespondentsContext);

  let handleEditRespondentDetailsClicked = () => {
    setRespondentToEdit(respondent);
    setEditRespondentDetailsModalOpen(true);
  };

  let handleCopyToOtherPhaseClicked = () => {
    setRespondentToEdit(respondent);
    setCopyToOtherPhaseModalOpen(true);
  };

  let handleCopyToInvOppClicked = () => {
    setRespondentToEdit(respondent);
    setCopyToInvOppModalOpen(true);
  };

  return (
    <>
      <CustomMaterialMenu size="small">
        <MenuItem style={{ fontSize: '1.4rem' }}>
          <ListItemIcon style={{ minWidth: 24 }}>
            <ContactMailIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
          </ListItemIcon>
          {respondent.most_recent_response_url ? (
            <a
              href={`/admin/phases/${phaseId}/qualtrics_metadata/${respondent.id}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              View Qualtrics Metadata
            </a>
          ) : (
            <p>No Qualtrics metadata available.</p>
          )}
        </MenuItem>
        <SurveyLinkMenuItems respondent={respondent} />
        {currentUserHasRole('superadmin') && (
          <>
            <MenuItem onClick={handleEditRespondentDetailsClicked} style={{ fontSize: '1.4rem' }}>
              <ListItemIcon style={{ minWidth: 24 }}>
                <EditIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
              </ListItemIcon>
              Edit Respondent Details
            </MenuItem>
            <MenuItem onClick={handleCopyToOtherPhaseClicked} style={{ fontSize: '1.4rem' }}>
              <ListItemIcon style={{ minWidth: 24 }}>
                <ContentCopyIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
              </ListItemIcon>
              Copy to a different Phase
            </MenuItem>
            <MenuItem onClick={handleCopyToInvOppClicked} style={{ fontSize: '1.4rem' }}>
              <ListItemIcon style={{ minWidth: 24 }}>
                <FileCopyIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
              </ListItemIcon>
              Copy to an Invitation Opportunity
            </MenuItem>
          </>
        )}
        {currentUserHasRole('superadmin') && (
          <MenuItem
            onClick={() => {
              sessionStorage.setItem('selectedRespondentIds', JSON.stringify([respondent.uuid]));
              const url = `/admin/payments/new?projectId=${respondent.project_id}&phaseId=${phaseId}`; //
              window.open(url, '_blank'); // '_blank' opens the URL in a new tab/window
            }}
            style={{ fontSize: '1.4rem' }}
          >
            <ListItemIcon style={{ minWidth: 24 }}>
              <LocalAtmIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
            </ListItemIcon>
            Create Payment for Respondent
          </MenuItem>
        )}
      </CustomMaterialMenu>
    </>
  );
};

export default RespondentRowMenu;
