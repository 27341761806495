import React, { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import { useHistory } from 'react-router-dom';
import ClientProjectFilterBar from '../components/ClientProjectFilterBar';
import ClientProjectsList from '../components/ClientProjectsList';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const ClientProjectsListContainer: React.FC = () => {
  const history = useHistory();

  const CreateProjectButton: FC = () => (
    <Button className="primary-btn" onClick={() => history.push('/admin/projects/new')}>
      Create Project
    </Button>
  );

  // Top line grid to inline-block

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="Client Projects" adminButtons={<CreateProjectButton />} />
      <Card style={{ padding: '1% 2%' }}>
        <ClientProjectFilterBar financial={false} />
        <ClientProjectsList />
      </Card>
    </Grid>
  );
};

export default ClientProjectsListContainer;
