import React, { useRef, Dispatch, SetStateAction } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { CSVLink } from 'react-csv';
import DataTable from '../../../dataTable';

import axios from '../../../utils/axios.utils';

interface ListCardProps {
  columns: any;
  title: string;
  data: any;
  leadSubmitting: boolean;
  setLeadSubmitting: Dispatch<SetStateAction<boolean>>;
}

const ListCard: React.FC<ListCardProps> = (props) => {
  const { columns, title, data, leadSubmitting, setLeadSubmitting } = props;
  let history = useHistory();

  const { leadGroupId } = useParams<Record<string, string>>();

  let showCheckboxes = title === 'Matches' && leadGroupId;

  // Ideally we would use a state instead of a Ref for this purpose.
  // But updating the state during the handleSelectRow function was causing the app to freeze
  const selectedRowsRef = useRef([]);

  const handleSelectRow = (state) => {
    selectedRowsRef.current = state.selectedRows;
  };

  const handleAddLeads = async () => {
    const ids = selectedRowsRef.current.map((row) => row.id);
    await axios.post<string, any>(`list_uploader`, { ids, lead_group_id: leadGroupId });
    history.push(`/admin/lead_groups/${leadGroupId}`);
    setLeadSubmitting(false);
  };

  return (
    <Accordion>
      <AccordionSummary>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography className="title" variant="h4">
            {title} - {data.length}
          </Typography>
          <CSVLink data={data} filename={`${title}`} style={{ textDecoration: 'none' }}>
            <Button className="primary-btn">Download</Button>
          </CSVLink>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-around' }}>
            {title !== 'No Match' && (
              <Typography className="title" variant="h5">
                <p>From our Database</p>
              </Typography>
            )}
            <Typography className="title" variant="h5">
              <p>From the Upload</p>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid>
              <DataTable
                columns={columns}
                data={data}
                striped
                highlightOnHover
                noHeader
                pagination
                paginationPerPage={25}
                paginationRowsPerPageOptions={[25]}
                selectableRows={showCheckboxes}
                selectableRowsHighlight
                onSelectedRowsChange={(state) => handleSelectRow(state)}
              />
            </Grid>
            {showCheckboxes && (
              <Grid xs={2} item style={{ margin: '10px' }}>
                <Button className="primary-btn" disabled={leadSubmitting} onClick={handleAddLeads}>
                  Add Selected Leads
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ListCard;
