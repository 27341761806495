import React, { FC, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

import { CSVLink } from 'react-csv';
import PaymentsList from '../components/PaymentsList';
import PaymentFilterBar from '../components/PaymentFilterBar';
import { PaymentsContext } from '../contexts/PaymentsContext';
import todaysDate from '../../utils/dateFunctions';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const PaymentsListPage: FC = () => {
  const history = useHistory();
  const { paymentsDownload, fetchPayments, knownAmountsFilterToUse } = useContext(PaymentsContext);

  useEffect(() => {
    const paymentFilters = sessionStorage.getItem('paymentFilters');

    const statusFilter = 'ransack[status_in][]=incomplete';

    if (paymentFilters) {
      fetchPayments(paymentFilters);
    } else {
      fetchPayments(`${statusFilter}&${knownAmountsFilterToUse}`);
    }
  }, []);

  const handleCreateButtonPressed = () => {
    sessionStorage.setItem('selectedRespondentIds', '');
    history.push('/admin/payments/new');
  };

  const PaymentEditButtons = () => {
    return (
      <>
        {paymentsDownload && (
          <CSVLink data={paymentsDownload} filename={`PAYMENTS-${todaysDate()}.csv`} style={{ textDecoration: 'none' }}>
            <Button className="primary-btn">Download Payments</Button>
          </CSVLink>
        )}
        <Button className="primary-btn" onClick={handleCreateButtonPressed}>
          Create Payments
        </Button>
      </>
    );
  };

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="Payments" adminButtons={<PaymentEditButtons />} />
      <Grid item xs={12}>
        <Card>
          <PaymentFilterBar />
          <PaymentsList />
        </Card>
      </Grid>
    </Grid>
  );
};

export default PaymentsListPage;
