import React, { FC, useState, useEffect, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui-bundle';
import DataTable from '../../dataTable/DataTable/DataTable';
import { ProjectsContext } from '../contexts/ProjectsContext';
import { UserContext } from '../../auth/contexts/UserContext';

import projectColumns from '../constants/projectColumns';

const ClientProjectsList: FC = () => {
  const { projects, fetchProjects, projectLoading, totalRecords, sortParams, setSortParams, filterParams } =
    useContext(ProjectsContext);

  let { currentUserHasRole } = useContext(UserContext);

  const { sortProjects } = useContext(ProjectsContext);
  const [isSorting, setIsSorting] = useState(false);

  const toggleSorting = (): void => {
    if (isSorting) {
      sortProjects($('.rdt_TableBody:first').sortable('serialize'));
      $('.rdt_TableBody:first').sortable('destroy');
    } else {
      $('.rdt_TableBody:first').sortable();
    }

    setIsSorting(!isSorting);
  };

  useEffect(() => {
    const internalProjectFilters =
      sessionStorage.getItem('internalProjectFilters') || 'ransack[client_name_not_eq]=Sommer Consulting';

    fetchProjects(internalProjectFilters);
  }, []);

  const history = useHistory();

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      project_number: 'project_number',
      name: 'name',
      client_name: 'client_name',
      start_date: 'project_timeline_overall_start_date',
      end_date: 'project_timeline_overall_end_date',
      project_manager_name: 'project_manager_last_name',
      recruiter_name: 'recruiter_last_name',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchProjects(`ransack[client_name_not_eq]=Sommer Consulting&${filterParams}&${sort}`);
  };

  const handlePageChange = (page: number) => {
    fetchProjects(`page=${page}&ransack[client_name_not_eq]=Sommer Consulting&${filterParams}&${sortParams}`);
  };

  return (
    <>
      {currentUserHasRole('admin') && (
        <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
          <Button className={isSorting ? 'primary-btn' : 'secondary-btn'} onClick={() => toggleSorting()}>
            {isSorting ? 'Save Order' : 'Sort Projects'}
          </Button>
        </Grid>
      )}
      <DataTable
        onRowClicked={(row: Record<string, string>) => history.push(`/admin/projects/${row.id}`)}
        columns={projectColumns()}
        data={projects}
        progressPending={projectLoading}
        striped
        highlightOnHover
        pointerOnHover
        noHeader
        pagination
        paginationPerPage={25}
        paginationRowsPerPageOptions={[25]}
        paginationServer
        paginationTotalRows={totalRecords}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleColumnSort}
      />
    </>
  );
};

export default ClientProjectsList;
