import React, { FC, useContext } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import ConfirmCancelModal from '../../modal/components/MaterialModal';

import { SurveyResponse } from '../../surveyResponses/model';

import { PhaseRespondentsContext } from '../../projectPhases/contexts/PhaseRespondentsContext';

const RespondentSurveyListModal: FC = () => {
  let { respondentToEdit, respondentSurveyListModalOpen, setRespondentSurveyListModalOpen } =
    useContext(PhaseRespondentsContext);

  return (
    <ConfirmCancelModal
      isOpen={respondentSurveyListModalOpen}
      title={`Survey Responses for ${respondentToEdit?.uuid}, Phase ${respondentToEdit?.phase_id}`}
      onCancel={() => setRespondentSurveyListModalOpen(false)}
      cancelLabel="Close"
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <Grid container>
        {respondentToEdit?.survey_responses?.map((response: SurveyResponse) => (
          <Grid item key={response?.id} xs={12} style={{ margin: 10 }}>
            <a
              href={response.qualtrics_responses_url}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Card style={{ padding: 5, backgroundColor: '#72a295' }}>
                <p>{response?.created_at}</p>
              </Card>
            </a>
          </Grid>
        ))}
      </Grid>
    </ConfirmCancelModal>
  );
};

export default RespondentSurveyListModal;
