import React, { FC, useContext } from 'react';

import RespondentForm from './RespondentForm';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

import { PhaseRespondentsContext } from '../../projectPhases/contexts/PhaseRespondentsContext';

const EditRespondentDetailsModal: FC = () => {
  let {
    fetchRespondents,
    respondentToEdit,
    editRespondentDetailsModalOpen,
    setEditRespondentDetailsModalOpen,
    currentPage,
    sortParams,
  } = useContext(PhaseRespondentsContext);

  const handleUpdateResponse = () => {
    const filterParams = sessionStorage.getItem(`respondentFilters-${respondentToEdit?.phase_id}`);
    const queryInputValue = sessionStorage.getItem(`respondentFilterQuery-${respondentToEdit?.phase_id}`);
    fetchRespondents(`phase_id=${respondentToEdit?.phase_id}&page=${currentPage}&${filterParams}&${sortParams}`);
  };

  return (
    <ConfirmCancelModal
      isOpen={editRespondentDetailsModalOpen}
      onCancel={() => setEditRespondentDetailsModalOpen(false)}
      title="Update Respondent"
    >
      <div style={{ margin: '40px 20px' }}>
        <RespondentForm
          respondent={respondentToEdit}
          afterAction={() => {
            setEditRespondentDetailsModalOpen(false);
            handleUpdateResponse();
          }}
        />
      </div>
    </ConfirmCancelModal>
  );
};

export default EditRespondentDetailsModal;
