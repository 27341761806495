import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import OpportunityOverviewPlaceholder from '../assets/OpportunityOverviewPlaceholder.png';
import { Opportunity, OpportunityKeyDetail } from '../../../opportunities/model';
import SeparatorBarComponent from '../../../navigation/components/SeparatorBarComponent';

interface OpportunityOverviewProps {
  insighterOpportunity: Opportunity;
}

const KeyDetailDisplay: React.FC<{ keyDetail: OpportunityKeyDetail }> = ({ keyDetail }) => {
  return (
    <Grid container style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={keyDetail?.s3_image_url} alt={keyDetail?.content} style={{ height: '50px', width: '50px' }} />
      </Grid>
      <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
        <div
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: keyDetail?.content }}
          style={{ marginBottom: 10 }}
          className="uploaded-content"
        />
      </Grid>
    </Grid>
  );
};

const OpportunityOverview: React.FC<OpportunityOverviewProps> = (props) => {
  const { insighterOpportunity } = props;

  return !insighterOpportunity ? (
    <></>
  ) : (
    <Grid container spacing={4} style={{ paddingLeft: '12%', paddingRight: '12%' }}>
      <Grid item xs={12} style={{ marginBottom: 10, cursor: 'pointer' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div style={{ maxWidth: 600, margin: 'auto', paddingBottom: 10 }}>
              <h2
                style={{
                  color: '#3B4255',
                  fontFamily: 'Crimson Text',
                  fontSize: '36px',
                  fontStyle: 'normal',
                  lineHeight: '150%',
                  fontWeight: 700,
                  marginBottom: 10,
                  paddingBottom: 5,
                }}
              >
                Opportunity Overview
              </h2>
              <div
                className="uploaded-content"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: insighterOpportunity.description }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ maxWidth: 600, margin: '0 auto' }}>
              <h2
                style={{
                  color: '#3B4255',
                  fontFamily: 'Crimson Text',
                  fontSize: '28px',
                  fontStyle: 'normal',
                  lineHeight: '150%',
                  fontWeight: 700,
                  marginBottom: 5,
                  paddingBottom: 5,
                }}
              >
                The Key Details
              </h2>
              {insighterOpportunity?.opportunity_key_details?.map((keyDetail, index) => (
                <>
                  <div key={`key_detail_${index}`}>
                    <KeyDetailDisplay keyDetail={keyDetail} />
                  </div>
                  {index !== insighterOpportunity?.opportunity_key_details?.length - 1 && <SeparatorBarComponent />}
                </>
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OpportunityOverview;
