import React, { useContext } from 'react';

import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuBook from '@mui/icons-material/MenuBook';

import { PhaseRespondentsContext } from '../../projectPhases/contexts/PhaseRespondentsContext';

import { Respondent } from '../model';

interface SurveyLinkMenuItemsProps {
  respondent: Respondent;
}

const SurveyLinkMenuItems: React.FC<SurveyLinkMenuItemsProps> = ({ respondent }) => {
  let surveyResponseCount = respondent.survey_responses?.length || 0;

  let { setRespondentToEdit, setRespondentSurveyListModalOpen } = useContext(PhaseRespondentsContext);

  let handleViewAllClicked = () => {
    setRespondentToEdit(respondent);
    setRespondentSurveyListModalOpen(true);
  };

  return (
    <>
      <MenuItem style={{ fontSize: '1.4rem' }}>
        <ListItemIcon style={{ minWidth: 24 }}>
          <AssignmentIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
        </ListItemIcon>
        {respondent.most_recent_response_url ? (
          <a
            href={respondent.most_recent_response_url}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            View Current Qualtrics Responses
          </a>
        ) : (
          <p style={{ color: 'gray' }}>No survey responses available.</p>
        )}
      </MenuItem>
      {surveyResponseCount > 1 && (
        <MenuItem style={{ fontSize: '1.4rem', cursor: 'pointer' }} onClick={handleViewAllClicked}>
          <ListItemIcon style={{ minWidth: 24 }}>
            <MenuBook fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
          </ListItemIcon>
          <p style={{ color: 'green', fontWeight: 'bold' }}>View All Qualtrics Responses</p>
        </MenuItem>
      )}
    </>
  );
};

export default SurveyLinkMenuItems;
