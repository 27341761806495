import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import { Frame } from '@shopify/polaris';
import { Helmet } from 'react-helmet';
import { UserContext } from './modules/auth/contexts/UserContext';

import RedesignTopNavigation from './modules/navigation/components/RedesignTopNavigation';
import RedesignSideNavigation from './modules/navigation/components/RedesignSideNavigation';

import ForgotPasswordPage from './modules/auth/pages/ForgotPassword';
import ForgotPasswordConfirmationPage from './modules/auth/pages/ForgotPasswordConfirmation';
import LoginPage from './modules/auth/pages/LoginPage';
import NewPasswordPage from './modules/auth/pages/NewPassword';
import NewProfilePasswordPage from './modules/auth/pages/NewProfilePassword';
import ConfirmEmailPage from './modules/auth/pages/ConfirmEmail';
import LogOutPage from './modules/auth/pages/LogOutPage';
import InterviewsListContainer from './modules/interviews/pages/InterviewsListContainer';
import NewInterviewPage from './modules/interviews/pages/NewInterview';
import EditInterviewPage from './modules/interviews/pages/EditInterview';
import InterviewSchedulePage from './modules/interviews/pages/InterviewSchedule';
import MarkCompletePage from './modules/interviews/pages/MarkComplete';
import PhasesListPage from './modules/projectPhases/pages/PhasesList';
import PhaseDetailPage from './modules/projectPhases/pages/PhaseDetail';
import SurveyMappingDetailPage from './modules/surveyMappings/pages/SurveyMappingDetail';
import EditSurveyMapping from './modules/surveyMappings/pages/EditSurveyMapping';
import EditPhasePage from './modules/projectPhases/pages/EditPhase';
import NewPhasePage from './modules/projectPhases/pages/NewPhase';
import DuplicatePhasePage from './modules/projectPhases/pages/DuplicatePhase';
import ClientProjectsListContainer from './modules/projects/pages/ClientProjectsListContainer';
import ClientProjectsListFinancialContainer from './modules/projects/pages/ClientProjectsListFinancialContainer';
import InternalProjectsListContainer from './modules/projects/pages/InternalProjectsListContainer';
import EditProjectPage from './modules/projects/pages/EditProject';
import ProjectDetailPage from './modules/projects/pages/ProjectDetail';
import NewProjectPage from './modules/projects/pages/NewProject';
import PhaseLaunchChecklistPage from './modules/projectPhases/pages/PhaseLaunchChecklist';
import LeadGroupDetailPage from './modules/leadGroups/pages/LeadGroupDetail';
import ListUploaderPage from './modules/adminTools/ListUploader/pages/ListUploaderPage';
import AccountMergerPage from './modules/adminTools/AccountMerger';
import ProjectLaunchChecklistPage from './modules/projects/pages/ProjectLaunchChecklist';
import ProjectWrapupChecklistPage from './modules/projects/pages/ProjectWrapupChecklist';
import InsighterFinderPage from './modules/insighterFinder/pages/InsighterFinder';
import QualtricsMetadataDetail from './modules/surveyResponses/pages/QualtricsMetadataDetail';
import InsighterOpportunitiesListPage from './modules/opportunities/pages/InsighterOpportunitiesList';
import HiringInfoPage from './modules/static/pages/HiringInfo';
import OpportunitiesListContainer from './modules/opportunities/pages/OpportunitiesListContainer';
import NewProjectOpportunityPage from './modules/opportunities/pages/NewProjectOpportunity';
import NewOpportunityPage from './modules/opportunities/pages/NewOpportunity';
import EditOpportunityPage from './modules/opportunities/pages/EditOpportunity';
import OpportunityDetailPage from './modules/opportunities/pages/OpportunityDetail';
import OpportunityKeyDetailsEditPage from './modules/opportunities/pages/OpportunityKeyDetailsEditPage';

import InsightersListContainer from './modules/insighters/pages/InsightersListContainer';
import InsighterDetailPage from './modules/insighters/pages/InsighterDetail';
import NewInsighterPage from './modules/insighters/pages/NewInsighterPage';
import EditInsighterPage from './modules/insighters/pages/EditInsighterPage';
import InsighterImpersonationPage from './modules/insighters/pages/InsighterImpersonation';

import InternalUsersListPage from './modules/internalUsers/pages/InternalUsersList';
import EditInternalUserPage from './modules/internalUsers/pages/EditInternalUser';
import NewInternalUserPage from './modules/internalUsers/pages/NewInternalUser';
import ApprovePaymentPage from './modules/payments/pages/ApprovePayment';
import PaymentsListPage from './modules/payments/pages/PaymentsListPage';
import InsighterPaymentPage from './modules/insighterPayments/pages/InsighterPayment';
import InsighterDashboardPage from './modules/insighterDashboard/pages/InsighterDashboard';
import ExpensesListContainer from './modules/expenses/pages/ExpensesListContainer';
import PhasesDashboardPage from './modules/dashboards/pages/PhasesDashboard';
import InsighterSignupDashboard from './modules/dashboards/pages/InsighterSignupDashboard';
import UpcomingChangesDashboardPage from './modules/dashboards/pages/UpcomingChangesDashboard';
import DemographicsDashboardPage from './modules/dashboards/pages/DemographicsDashboard';
import DemographicDrillDownDashboardPage from './modules/dashboards/pages/DemographicDrillDownDashboard';
import ProjectSignupsDashboardContainer from './modules/dashboards/pages/ProjectSignupsDashboardContainer';
import EditProfilePage from './modules/registrations/pages/EditProfile';
import RegisterInsighterPage from './modules/registrations/pages/RegisterInsighterPage';
import OptionHealthInformationPage from './modules/registrations/pages/OptionalHealthInformation';
import AdminToolsPage from './modules/adminTools/AdminToolsPage';
import ConditionsListPage from './modules/conditions/pages/ConditionsList';
import ConditionDetailPage from './modules/conditions/pages/ConditionDetail';
import EthnicitiesListPage from './modules/ethnicities/pages/EthnicitiesList';
import EditEthnicityPage from './modules/ethnicities/pages/EditEthnicity';
import EthnicityDetailPage from './modules/ethnicities/pages/EthnicityDetail';
import EditConditionPage from './modules/conditions/pages/EditCondition';
import EditConditionCategoryPage from './modules/conditions/pages/EditConditionCategory';
import MergeConditions from './modules/conditions/pages/MergeConditions';
import NewConditionPage from './modules/conditions/pages/NewCondition';
import NewConditionCategoryPage from './modules/conditions/pages/NewConditionCategory';
import ConditionCategoriesList from './modules/conditions/pages/ConditionCategoriesList';

import SpecialtiesListPage from './modules/specialties/pages/SpecialtiesList';
import SpecialtyDetailPage from './modules/specialties/pages/SpecialtyDetail';
import EditSpecialtyPage from './modules/specialties/pages/EditSpecialty';
import NewSpecialtyPage from './modules/specialties/pages/NewSpecialty';

import InsighterTagsListPage from './modules/insighterTags/pages/InsighterTagsList';
import InsighterTagDetailPage from './modules/insighterTags/pages/InsighterTagDetail';
import EditInsighterTagPage from './modules/insighterTags/pages/EditInsighterTag';
import NewInsighterTagPage from './modules/insighterTags/pages/NewInsighterTag';

import CalendlyEventsListPage from './modules/calendlyEvents/pages/CalendlyEventsList';
import EditCalendlyEventPage from './modules/calendlyEvents/pages/EditCalendlyEvent';

import TremendousCampaignListPage from './modules/tremendousCampaigns/pages/TremendousCampaignsListPage';
import TremendousCampaignCreatePage from './modules/tremendousCampaigns/pages/TremendousCampaignCreatePage';

import InsighterRolesListPage from './modules/insighterRoles/pages/InsighterRolesList';
import InsighterRoleDetailPage from './modules/insighterRoles/pages/InsighterRoleDetail';
import EditInsighterRolePage from './modules/insighterRoles/pages/EditInsighterRole';
import NewInsighterRolePage from './modules/insighterRoles/pages/NewInsighterRole';

import AdminTopNavigation from './modules/navigation/components/AdminTopNavigation';
import PrivacyPolicyPage from './modules/static/pages/PrivacyPolicy';
import CookiePolicyPage from './modules/static/pages/CookiePolicy';
import TermsOfUsePage from './modules/static/pages/TermsOfUse';
import PayPortalTerms from './modules/static/pages/PayPortalTerms';
import EditPaymentPage from './modules/payments/pages/EditPaymentPage';
import EditExpensePage from './modules/payments/pages/EditExpensePage';
import NewExpensePage from './modules/payments/pages/NewExpensePage';
import NewPaymentPage from './modules/payments/pages/NewPaymentPage';
import InvitationsDashboardPage from './modules/invitations/pages/InvitationsDashboard';
import InvitationsListPage from './modules/invitations/pages/InvitationsList';
import InvitationOpportunitiesListPage from './modules/invitationOpportunities/pages/InvitationOpportunitiesList';
import InvitationOpportunityDetailPage from './modules/invitationOpportunities/pages/InvitationOpportunityDetailPage';
import EditInvitationPartnerPage from './modules/invitationPartners/pages/EditInvitationPartner';
import NewInvitationPartnerPage from './modules/invitationPartners/pages/NewInvitationPartner';
import EditInvitationOpportunityPage from './modules/invitationOpportunities/pages/EditInvitationOpportunity';
import NewInvitationOpportunityPage from './modules/invitationOpportunities/pages/NewInvitationOpportunity';
import DuplicateInvitationOpportunityPage from './modules/invitationOpportunities/pages/DuplicateInvitationOpportunity';

import AdminSideNavigation from './modules/navigation/components/AdminSideNavigation';
import RecruitTopNavigation from './modules/navigation/components/RecruitTopNavigation';
import RecruitSideNavigation from './modules/navigation/components/RecruitSideNavigation';

import RecruitmentPartnersListPage from './modules/recruitmentPartners/pages/RecruitmentPartnersList';
import EditRecruitmentPartnerPage from './modules/recruitmentPartners/pages/EditRecruitmentPartner';
import NewRecruitmentPartnerPage from './modules/recruitmentPartners/pages/NewRecruitmentPartner';

import TestimonialsListPage from './modules/testimonials/pages/TestimonialsListPage';
import CreateTestimonialPage from './modules/testimonials/pages/CreateTestimonialPage';
import TestimonialDetailPage from './modules/testimonials/pages/TestimonialDetailPage';
import EditTestimonialPage from './modules/testimonials/pages/EditTestimonialPage';

import BlogPostsListPage from './modules/blogPosts/pages/BlogPostsListPage';
import CreateBlogPostPage from './modules/blogPosts/pages/CreateBlogPostPage';
import EditBlogPostPage from './modules/blogPosts/pages/EditBlogPostPage';
import BlogPostDetailPage from './modules/redesignedFrontend/blogDetail/BlogPostDetailPage';

import BlogAuthorsListPage from './modules/blogPosts/pages/BlogAuthorsListPage';
import CreateBlogAuthorPage from './modules/blogPosts/pages/CreateBlogAuthorPage';
import EditBlogAuthorPage from './modules/blogPosts/pages/EditBlogAuthorPage';
import BlogAuthorDetailPage from './modules/redesignedFrontend/blogDetail/BlogAuthorDetailPage';

import TeamBiosListPage from './modules/teamBios/pages/TeamBiosListPage';
import CreateTeamBioPage from './modules/teamBios/pages/CreateTeamBioPage';
import EditTeamBioPage from './modules/teamBios/pages/EditTeamBioPage';
import TeamBioDetailPage from './modules/teamBios/pages/TeamBioDetailPage';

import EventResourcesListPage from './modules/eventResources/pages/EventResourcesListPage';
import CreateEventResourcePage from './modules/eventResources/pages/CreateEventResourcePage';
import EventResourceDetailPage from './modules/eventResources/pages/EventResourceDetailPage';
import EditEventResourcePage from './modules/eventResources/pages/EditEventResourcePage';

import CaseStudiesListPage from './modules/caseStudies/pages/CaseStudiesListPage';
import CreateCaseStudyPage from './modules/caseStudies/pages/CreateCaseStudyPage';
import CaseStudyDetailPage from './modules/caseStudies/pages/CaseStudyDetailPage';
import CaseStudyEditPage from './modules/caseStudies/pages/CaseStudyEditPage';
import CaseStudyEditImagesPage from './modules/caseStudies/pages/CaseStudyEditImagesPage';
import CaseStudyEditKeyDetailsPage from './modules/caseStudies/pages/CaseStudyEditKeyDetailsPage';

import IconsListPage from './modules/icons/pages/IconsListPage';
import CreateIconPage from './modules/icons/pages/IconCreatePage';
import IconEditPage from './modules/icons/pages/IconEditPage';

import RedesignedHomepage from './modules/redesignedFrontend/homePage/RedesignedHomepage';
import GeneralOpportunitiesListPage from './modules/redesignedFrontend/opportunitiesListPage/GeneralOpportunitiesListPage';
import HealthcareProfessionalOpportunitiesListPage from './modules/redesignedFrontend/opportunitiesListPage/HealthcareProfessionalOpportuntiesListPage';
import RedesignFrequentlyAskedQuestionsPage from './modules/redesignedFrontend/faqPage/FrequentlyAskedQuestions';
import RedesignOpportunityDetailPage from './modules/redesignedFrontend/opportunityDetailPage/OpportunityDetailPage';
import RedesignedAboutPage from './modules/redesignedFrontend/aboutPage/AboutPage';
import ResourcesPage from './modules/redesignedFrontend/resourcesPage/ResourcesPage';

import CreateTopicPage from './modules/topics/pages/CreateTopicPage';
import TopicsListPage from './modules/topics/pages/TopicsListPage';
import EditTopicPage from './modules/topics/pages/EditTopicPage';

const Routes: React.FC = () => {
  const { user, currentUserHasRole } = React.useContext(UserContext);
  const [mobileNavigationActive, setMobileNavigationActive] = React.useState<boolean>(false);
  const skipToContentRef = React.useRef<HTMLAnchorElement>(null);

  const topNavigation =
    currentUserHasRole('internal_user') && window.location.href.includes('admin') ? (
      <AdminTopNavigation mobileNavigationActive={mobileNavigationActive} setMobileNavigationActive={setMobileNavigationActive} />
    ) : (
      <RecruitTopNavigation mobileNavigationActive={mobileNavigationActive} setMobileNavigationActive={setMobileNavigationActive} />
    );

  const sideNavigation = currentUserHasRole('internal_user') && window.location.href.includes('admin') ? <AdminSideNavigation /> : <RecruitSideNavigation />;

  const ProtectedUserRoute = ({ component: Component, path, exact = true }) => (
    <>
      <Helmet>
        <script src="https://cmp.osano.com/AzqaTyTiK2WAa4JV/bf2b4f2c-1f5c-4a47-9471-15addd122031/osano.js" />
      </Helmet>
      <Route
        exact={exact}
        path={path}
        render={(props) =>
          user?.id ? (
            <Frame
              topBar={topNavigation}
              navigation={sideNavigation}
              showMobileNavigation={mobileNavigationActive}
              onNavigationDismiss={() => setMobileNavigationActive(false)}
              skipToContentTarget={skipToContentRef}
            >
              <Component props={props} component />
            </Frame>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </>
  );

  const ProtectedAdminRoute = ({ component: Component, path, exact = true }) => (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        user?.roles?.includes('internal_user') ? (
          <Frame
            topBar={topNavigation}
            navigation={sideNavigation}
            showMobileNavigation={mobileNavigationActive}
            onNavigationDismiss={() => setMobileNavigationActive(false)}
            skipToContentTarget={skipToContentRef}
          >
            <Component props={props} component />
          </Frame>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  const RedesignRoute = ({ component: Component, path }) => (
    <>
      <Helmet>
        <script src="https://cmp.osano.com/AzqaTyTiK2WAa4JV/bf2b4f2c-1f5c-4a47-9471-15addd122031/osano.js" />
      </Helmet>
      <Route
        path={path}
        render={(props) => (
          <>
            {mobileNavigationActive ? (
              <RedesignSideNavigation mobileNavigationActive={mobileNavigationActive} setMobileNavigationActive={setMobileNavigationActive} />
            ) : (
              <RedesignTopNavigation setMobileNavigationActive={setMobileNavigationActive} />
            )}

            <Component props={props} component />
          </>
        )}
      />
    </>
  );

  return user ? (
    <Switch>
      <RedesignRoute path="/login" component={() => <LoginPage />} />

      {/* Insighter pages */}
      <ProtectedUserRoute exact path="/dashboard" component={() => <InsighterDashboardPage isLoading={false} />} />
      <ProtectedUserRoute exact path="/dashboard/:impersonatedUserId" component={() => <InsighterDashboardPage isLoading={false} />} />
      <ProtectedUserRoute exact path="/invitations" component={() => <InvitationsDashboardPage />} />
      <ProtectedUserRoute exact path="/invitations/:impersonatedUserId" component={() => <InvitationsDashboardPage />} />
      <ProtectedUserRoute exact path="/profile" component={() => <EditProfilePage />} />
      <ProtectedUserRoute exact path="/payments" component={() => <InsighterPaymentPage />} />
      <ProtectedUserRoute exact path="/payments/:impersonatedUserId" component={() => <InsighterPaymentPage />} />
      <ProtectedUserRoute exact path="/logout" component={() => <LogOutPage />} />

      {/* Admin Dashboard pages */}
      <ProtectedAdminRoute exact path="/admin/dashboard" component={() => <UpcomingChangesDashboardPage />} />
      <ProtectedAdminRoute exact path="/admin/dashboard/phases" component={() => <PhasesDashboardPage isLoading={false} />} />
      <ProtectedAdminRoute exact path="/admin/dashboard/signups" component={() => <InsighterSignupDashboard isLoading={false} />} />
      <ProtectedAdminRoute exact path="/admin/dashboard/upcoming-changes" component={() => <UpcomingChangesDashboardPage />} />
      <ProtectedAdminRoute exact path="/admin/dashboard/demographics" component={() => <DemographicsDashboardPage isLoading={false} />} />
      <ProtectedAdminRoute exact path="/admin/dashboard/demographic-drill-down" component={() => <DemographicDrillDownDashboardPage isLoading={false} />} />
      <ProtectedAdminRoute exact path="/admin/dashboard/project-signups" component={() => <ProjectSignupsDashboardContainer />} />

      {/* Payment pages */}
      <ProtectedAdminRoute exact path="/admin/payments/:paymentId/approve" component={() => <ApprovePaymentPage />} />
      <ProtectedAdminRoute exact path="/admin/payments" component={() => <PaymentsListPage />} />
      <ProtectedAdminRoute exact path="/admin/payments/new" component={() => <NewPaymentPage />} />
      <ProtectedAdminRoute exact path="/admin/projects/:projectId/payments/new" component={() => <NewPaymentPage />} />
      <ProtectedAdminRoute exact path="/admin/projects/:projectId/phases/:phaseId/payments/new" component={() => <NewPaymentPage />} />
      <ProtectedAdminRoute exact path="/admin/payments/:paymentId" component={() => <EditPaymentPage />} />

      {/* Expense pages */}
      <ProtectedAdminRoute exact path="/admin/expenses" component={() => <ExpensesListContainer />} />
      <ProtectedAdminRoute exact path="/admin/expenses/new" component={() => <NewExpensePage />} />
      <ProtectedAdminRoute exact path="/admin/expenses/:paymentId" component={() => <EditExpensePage />} />

      {/* Interview Pages */}
      <ProtectedAdminRoute exact path="/admin/interviews/mark-complete/:interviewId" component={() => <MarkCompletePage />} />
      <ProtectedAdminRoute exact path="/admin/interviews/new" component={() => <NewInterviewPage />} />
      <ProtectedAdminRoute exact path="/admin/interviews/:interviewId" component={() => <EditInterviewPage />} />
      <ProtectedAdminRoute exact path="/admin/interviews" component={() => <InterviewsListContainer />} />
      <ProtectedAdminRoute exact path="/admin/interview-schedule" component={() => <InterviewSchedulePage />} />

      {/* Insighter Management Pages */}
      <ProtectedAdminRoute exact path="/admin/insighters/new" component={() => <NewInsighterPage />} />
      <ProtectedAdminRoute exact path="/admin/insighters/:insighterId/view" component={() => <InsighterImpersonationPage />} />
      <ProtectedAdminRoute exact path="/admin/insighters/:insighterId/edit" component={() => <EditInsighterPage />} />
      <ProtectedAdminRoute exact path="/admin/insighters/:insighterId" component={() => <InsighterDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/insighters" component={() => <InsightersListContainer />} />

      {/* LeadGroup pages */}
      <ProtectedAdminRoute exact path="/admin/lead_groups/:leadGroupId" component={() => <LeadGroupDetailPage />} />

      {/* Insighter Opportunity Pages */}
      <RedesignRoute path="/opportunities/general" component={() => <GeneralOpportunitiesListPage />} />
      <RedesignRoute path="/opportunities/professionals" component={() => <HealthcareProfessionalOpportunitiesListPage />} />
      <RedesignRoute path="/opportunities/:opportunityId" component={() => <RedesignOpportunityDetailPage />} />

      {/* Admin Opportunity Pages */}
      <ProtectedUserRoute exact path="/opportunities" component={() => <InsighterOpportunitiesListPage />} />
      <ProtectedUserRoute exact path="/opportunities/:impersonatedUserId" component={() => <InsighterOpportunitiesListPage />} />
      <ProtectedAdminRoute exact path="/admin/projects/:projectId/opportunities/new" component={() => <NewProjectOpportunityPage />} />
      <ProtectedAdminRoute exact path="/admin/opportunities/new" component={() => <NewOpportunityPage />} />
      <ProtectedAdminRoute exact path="/admin/opportunities/:opportunityId/edit" component={() => <EditOpportunityPage />} />
      <ProtectedAdminRoute exact path="/admin/opportunities/:opportunityId/key-details" component={() => <OpportunityKeyDetailsEditPage />} />
      <ProtectedAdminRoute exact path="/admin/opportunities/:opportunityId" component={() => <OpportunityDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/opportunities" component={() => <OpportunitiesListContainer />} />

      {/* Project Pages */}
      <ProtectedAdminRoute exact path="/admin/projects/new" component={() => <NewProjectPage />} />
      <ProtectedAdminRoute exact path="/admin/projects/:projectId/edit" component={() => <EditProjectPage />} />
      <ProtectedAdminRoute exact path="/admin/projects/:projectId/launch-checklist" component={() => <ProjectLaunchChecklistPage />} />
      <ProtectedAdminRoute exact path="/admin/projects/:projectId/wrap-up-checklist" component={() => <ProjectWrapupChecklistPage />} />
      <ProtectedAdminRoute exact path="/admin/projects/:projectId" component={() => <ProjectDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/client-projects" component={() => <ClientProjectsListContainer />} />
      <ProtectedAdminRoute exact path="/admin/client-projects-financial" component={() => <ClientProjectsListFinancialContainer />} />
      <ProtectedAdminRoute exact path="/admin/internal-projects" component={() => <InternalProjectsListContainer />} />

      {/* Project Phase Pages */}
      <ProtectedAdminRoute exact path="/admin/phases/new" component={() => <NewPhasePage />} />
      <ProtectedAdminRoute exact path="/admin/phases" component={() => <PhasesListPage />} />
      <ProtectedAdminRoute exact path="/admin/phases/:phaseId/qualtrics_metadata/:respondentId" component={() => <QualtricsMetadataDetail />} />
      <ProtectedAdminRoute exact path="/admin/phases/:phaseId/survey-mapping" component={() => <SurveyMappingDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/phases/:phaseId/edit-survey-mapping" component={() => <EditSurveyMapping />} />
      <ProtectedAdminRoute exact path="/admin/phases/:phaseId" component={() => <PhaseDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/phases/:phaseId/edit" component={() => <EditPhasePage />} />
      <ProtectedAdminRoute exact path="/admin/phases/:phaseId/duplicate" component={() => <DuplicatePhasePage />} />
      <ProtectedAdminRoute exact path="/admin/phases/:phaseId/launch-checklist" component={() => <PhaseLaunchChecklistPage />} />
      <ProtectedAdminRoute exact path="/admin/projects/:projectId/phases/new" component={() => <NewPhasePage />} />

      {/* Insighter Finder Pages */}
      <ProtectedAdminRoute exact path="/admin/insighter_finder" component={() => <InsighterFinderPage />} />
      <ProtectedAdminRoute exact path="/admin/insighter_finder/:leadGroupId" component={() => <InsighterFinderPage />} />

      {/* Internal User Pages */}
      <ProtectedAdminRoute exact path="/admin/users/new" component={() => <NewInternalUserPage />} />
      <ProtectedAdminRoute exact path="/admin/users/:userId" component={() => <EditInternalUserPage />} />
      <ProtectedAdminRoute exact path="/admin/users" component={() => <InternalUsersListPage />} />

      {/* Insighter Attributes */}
      <ProtectedAdminRoute exact path="/admin/conditions/new" component={() => <NewConditionPage />} />
      <ProtectedAdminRoute exact path="/admin/conditions/merge" component={() => <MergeConditions />} />
      <ProtectedAdminRoute exact path="/admin/conditions/categories" component={() => <ConditionCategoriesList />} />
      <ProtectedAdminRoute exact path="/admin/conditions/categories/new" component={() => <NewConditionCategoryPage />} />
      <ProtectedAdminRoute exact path="/admin/conditions/:conditionId/edit" component={() => <EditConditionPage />} />
      <ProtectedAdminRoute exact path="/admin/condition_categories/:conditionCategoryId/edit" component={() => <EditConditionCategoryPage />} />
      <ProtectedAdminRoute exact path="/admin/conditions/:conditionId" component={() => <ConditionDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/conditions" component={() => <ConditionsListPage isLoading={false} />} />
      <ProtectedAdminRoute exact path="/admin/ethnicities/:ethnicityId/edit" component={() => <EditEthnicityPage />} />
      <ProtectedAdminRoute exact path="/admin/ethnicities/:ethnicityId" component={() => <EthnicityDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/ethnicities" component={() => <EthnicitiesListPage isLoading={false} />} />
      <ProtectedAdminRoute exact path="/admin/specialties/new" component={() => <NewSpecialtyPage />} />
      <ProtectedAdminRoute exact path="/admin/specialties/:specialtyId/edit" component={() => <EditSpecialtyPage />} />
      <ProtectedAdminRoute exact path="/admin/specialties/:specialtyId" component={() => <SpecialtyDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/specialties" component={() => <SpecialtiesListPage isLoading={false} />} />
      <ProtectedAdminRoute exact path="/admin/insighter-roles/new" component={() => <NewInsighterRolePage />} />
      <ProtectedAdminRoute exact path="/admin/insighter-roles/:insighterRoleId/edit" component={() => <EditInsighterRolePage />} />
      <ProtectedAdminRoute exact path="/admin/insighter-roles/:insighterRoleId" component={() => <InsighterRoleDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/insighter-roles" component={() => <InsighterRolesListPage isLoading={false} />} />
      <ProtectedAdminRoute exact path="/admin/insighter-tags/new" component={() => <NewInsighterTagPage />} />
      <ProtectedAdminRoute exact path="/admin/insighter-tags/:insighterTagId/edit" component={() => <EditInsighterTagPage />} />
      <ProtectedAdminRoute exact path="/admin/insighter-tags/:insighterTagId" component={() => <InsighterTagDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/insighter-tags" component={() => <InsighterTagsListPage isLoading={false} />} />

      {/* Calendly Event Pages */}
      <ProtectedAdminRoute exact path="/admin/calendly-events/:calendlyEventId/edit" component={() => <EditCalendlyEventPage />} />
      <ProtectedAdminRoute exact path="/admin/calendly-events" component={() => <CalendlyEventsListPage isLoading={false} />} />

      {/* Tremendous Campaign Pages */}
      <ProtectedAdminRoute exact path="/admin/tremendous-campaigns" component={() => <TremendousCampaignListPage />} />
      <ProtectedAdminRoute exact path="/admin/tremendous-campaigns/new" component={() => <TremendousCampaignCreatePage />} />

      {/* Recruitment Partners Pages */}
      <ProtectedAdminRoute exact path="/admin/recruitment-partners/new" component={() => <NewRecruitmentPartnerPage />} />
      <ProtectedAdminRoute exact path="/admin/recruitment-partners/:recruitmentPartnerId/edit" component={() => <EditRecruitmentPartnerPage />} />
      <ProtectedAdminRoute exact path="/admin/recruitment-partners" component={() => <RecruitmentPartnersListPage isLoading={false} />} />

      {/* Invitation Pages */}
      <ProtectedAdminRoute exact path="/admin/invitations" component={() => <InvitationsListPage />} />
      <ProtectedAdminRoute exact path="/admin/invitation-opportunities" component={() => <InvitationOpportunitiesListPage />} />
      <ProtectedAdminRoute exact path="/admin/invitation-opportunities/new" component={() => <NewInvitationOpportunityPage />} />
      <ProtectedAdminRoute exact path="/admin/invitation-opportunities/:invitationOpportunityId" component={() => <InvitationOpportunityDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/invitation-opportunities/:invitationOpportunityId/edit" component={() => <EditInvitationOpportunityPage />} />
      <ProtectedAdminRoute exact path="/admin/invitation-opportunities/:invitationOpportunityId/duplicate" component={() => <DuplicateInvitationOpportunityPage />} />
      <ProtectedAdminRoute exact path="/admin/invitation-opportunities/:invitationOpportunityId/invitation-partners/new" component={() => <NewInvitationPartnerPage />} />
      <ProtectedAdminRoute exact path="/admin/invitation-opportunities/:invitationOpportunityId/invitation-partners/:invitationPartnerId/edit" component={() => <EditInvitationPartnerPage />} />

      {/* Admin Tools */}
      <ProtectedAdminRoute exact path="/admin/tools" component={() => <AdminToolsPage />} />
      <ProtectedAdminRoute exact path="/admin/account_merger" component={() => <AccountMergerPage />} />
      <ProtectedAdminRoute exact path="/admin/list_uploader" component={() => <ListUploaderPage />} />
      <ProtectedAdminRoute exact path="/admin/list_uploader/:leadGroupId" component={() => <ListUploaderPage />} />

      <RedesignRoute path="/blog-posts/:blogPostId" component={() => <BlogPostDetailPage />} />

      <ProtectedAdminRoute exact path="/admin/blog-posts" component={() => <BlogPostsListPage />} />
      <ProtectedAdminRoute exact path="/admin/blog-posts/create" component={() => <CreateBlogPostPage />} />
      <ProtectedAdminRoute exact path="/admin/blog-posts/:blogPostId/edit" component={() => <EditBlogPostPage />} />

      <ProtectedAdminRoute exact path="/admin/blog-authors/new" component={() => <CreateBlogAuthorPage />} />
      <ProtectedAdminRoute exact path="/admin/blog-authors/:blogAuthorId" component={() => <BlogAuthorDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/blog-authors" component={() => <BlogAuthorsListPage />} />
      <ProtectedAdminRoute exact path="/admin/blog-authors/:blogAuthorId/edit" component={() => <EditBlogAuthorPage />} />

      <ProtectedAdminRoute exact path="/admin/team-bios/new" component={() => <CreateTeamBioPage />} />
      <ProtectedAdminRoute exact path="/admin/team-bios/:teamBioId" component={() => <TeamBioDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/team-bios" component={() => <TeamBiosListPage />} />
      <ProtectedAdminRoute exact path="/admin/team-bios/:teamBioId/edit" component={() => <EditTeamBioPage />} />

      <ProtectedAdminRoute exact path="/admin/testimonials" component={() => <TestimonialsListPage />} />
      <ProtectedAdminRoute exact path="/admin/testimonials/create" component={() => <CreateTestimonialPage />} />
      <ProtectedAdminRoute exact path="/admin/testimonials/:testimonialId" component={() => <TestimonialDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/testimonials/:testimonialId/edit" component={() => <EditTestimonialPage />} />

      <ProtectedAdminRoute exact path="/admin/event-resources" component={() => <EventResourcesListPage />} />
      <ProtectedAdminRoute exact path="/admin/event-resources/create" component={() => <CreateEventResourcePage />} />
      <ProtectedAdminRoute exact path="/admin/event-resources/:eventResourceId" component={() => <EventResourceDetailPage />} />
      <ProtectedAdminRoute exact path="/admin/event-resources/:eventResourceId/edit" component={() => <EditEventResourcePage />} />

      <RedesignRoute path="/case-studies/:caseStudyId" component={() => <CaseStudyDetailPage />} />

      <ProtectedAdminRoute exact path="/admin/case-studies" component={() => <CaseStudiesListPage />} />
      <ProtectedAdminRoute exact path="/admin/case-studies/create" component={() => <CreateCaseStudyPage />} />
      <ProtectedAdminRoute exact path="/admin/case-studies/:caseStudyId/edit" component={() => <CaseStudyEditPage />} />
      <ProtectedAdminRoute exact path="/admin/case-studies/:caseStudyId/edit-images" component={() => <CaseStudyEditImagesPage />} />
      <ProtectedAdminRoute exact path="/admin/case-studies/:caseStudyId/key-details" component={() => <CaseStudyEditKeyDetailsPage />} />

      <ProtectedAdminRoute exact path="/admin/icons" component={() => <IconsListPage />} />
      <ProtectedAdminRoute exact path="/admin/icons/create" component={() => <CreateIconPage />} />
      <ProtectedAdminRoute exact path="/admin/icons/:iconId/edit" component={() => <IconEditPage />} />

      <ProtectedAdminRoute exact path="/admin/topics/create" component={() => <CreateTopicPage />} />
      <ProtectedAdminRoute exact path="/admin/topics" component={() => <TopicsListPage />} />
      <ProtectedAdminRoute exact path="/admin/topics/:topicId/edit" component={() => <EditTopicPage />} />

      {/* Admin Pages */}
      <ProtectedAdminRoute exact path="/admin/case-studies/:caseStudyId/edit" component={() => <CaseStudyEditPage />} />

      <RedesignRoute path="/public-opportunities/:opportunityId" component={() => <RedesignOpportunityDetailPage />} />
      <RedesignRoute path="/public-opportunities/" component={() => <GeneralOpportunitiesListPage />} />
      <RedesignRoute path="/meet-the-team" component={() => <RedesignedAboutPage />} />
      <RedesignRoute path="/hiring-info" component={() => <HiringInfoPage />} />
      <RedesignRoute path="/password-reset/sent" component={() => <ForgotPasswordConfirmationPage />} />
      <RedesignRoute path="/password-reset" component={() => <ForgotPasswordPage />} />
      <RedesignRoute path="/privacy-policy" component={() => <PrivacyPolicyPage />} />
      <RedesignRoute path="/cookie-policy" component={() => <CookiePolicyPage />} />
      <RedesignRoute path="/terms-of-use" component={() => <TermsOfUsePage />} />

      <RedesignRoute path="/about" component={() => <RedesignedAboutPage />} />

      <RedesignRoute path="/frequently-asked-questions" component={() => <RedesignFrequentlyAskedQuestionsPage />} />
      <RedesignRoute path="/resources/:category" component={() => <ResourcesPage />} />
      <RedesignRoute path="/resources" component={() => <ResourcesPage />} />

      <RedesignRoute path="/pay-portal-terms" component={() => <PayPortalTerms />} />
      {/* Sign-Up Pages */}
      <ProtectedUserRoute exact path="/sign-up/optional-information" component={() => <OptionHealthInformationPage />} />
      {/* Auth Pages */}

      <RedesignRoute path="/confirm-email" component={() => <ConfirmEmailPage />} />
      <RedesignRoute path="/new-password/:confirmationToken" component={() => <NewPasswordPage />} />
      <ProtectedUserRoute path="/profile/password" component={() => <NewProfilePasswordPage />} />
      <RedesignRoute path="/sign-up" component={() => <RegisterInsighterPage isLoading={false} />} />
      {currentUserHasRole('internal_user') && <ProtectedUserRoute exact path="/" component={() => <Redirect to="/admin/client-projects" />} />}
      {currentUserHasRole('insighter') && <ProtectedUserRoute exact path="/" component={() => <Redirect to="/dashboard" />} />}
      <RedesignRoute path="/" component={() => <RedesignedHomepage />} />
    </Switch>
  ) : (
    <Switch />
  );
};

export default Routes;
