import React from 'react';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import DataTable from '../../dataTable/DataTable/DataTable';
import { Project } from '../model';
import { Payment } from '../../payments/model';
import todaysDate from '../../utils/dateFunctions';
import { projectPaymentColumns } from '../constants/projectPaymentColumns';
import { UserContext } from '../../auth/contexts/UserContext';

interface ProjectPaymentsProps {
  project: Project;
  expenses: Payment[];
}

const ProjectPayments: React.FC<ProjectPaymentsProps> = (props) => {
  let { expenses, project } = props;
  let history = useHistory();
  let { currentUserHasRole } = React.useContext(UserContext);

  return (
    <Card style={{ padding: '2% ' }}>
      <div style={{ marginBottom: 15 }}>
        <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
          <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
            <h2 className="Polaris-Heading">Expenses</h2>
          </div>
          <div className="Polaris-Stack__Item">
            <div className="Polaris-ButtonGroup">
              {currentUserHasRole('admin') && (
                <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                  <Button
                    className="primary-btn"
                    onClick={() => history.push(`/admin/expenses/new?projectId=${project.id}`)}
                  >
                    New Expense
                  </Button>
                  <CSVLink
                    data={project?.expense_csv_data}
                    filename={`Project-${project?.project_number}-Expenses-${todaysDate()}.csv`}
                    style={{ textDecoration: 'none', marginLeft: 10 }}
                  >
                    <Button className="primary-btn">Download Expenses</Button>
                  </CSVLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DataTable
        onRowClicked={(payment: Payment) => history.push(`/admin/expenses/${payment.id}`)}
        columns={projectPaymentColumns}
        data={expenses}
        pagination
        striped
        highlightOnHover
        pointerOnHover
        noHeader
      />
      <div className="Polaris-Card__Section">
        <p>
          <strong>Insighter Brand Partner Total:</strong> $ {project.insighter_brand_partner_sum}
        </p>
        <p>
          <strong>Ads Total:</strong> $ {project.ads_sum}
        </p>
        <p>
          <strong>Other Expenses Total:</strong> $ {project.expenses_payments_sum}
        </p>
        <br />
        <p>
          <strong>Total Marketing Budget:</strong> $ {project.marketing_budget}
        </p>
        <p>
          <strong>Marketing Budget Remaining:</strong> $ {project.marketing_budget_remaining}
        </p>
        <br />
        <p>
          <strong>Number of Qualtrics Responses:</strong> {project.number_of_qualtrics_responses}
        </p>
      </div>
    </Card>
  );
};

export default ProjectPayments;
