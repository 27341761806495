import React from 'react';

import Grid from '@material-ui/core/Grid';

import OpportunityImageDropZone from './OpportunityImageDropZone';

import { Opportunity } from '../model';

interface OpportunityImagesProps {
  opportunity: Opportunity;
  fetchOpportunity: (opportunity_id: string) => Promise<void>;
}

const OpportunityImages: React.FC<OpportunityImagesProps> = (props) => {
  let { opportunity, fetchOpportunity } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <h2 className="Polaris-Heading">Opportunity Images</h2>
      </Grid>
      <Grid item xs={12} md={6} style={{ marginTop: 10 }}>
        <OpportunityImageDropZone
          title="Homepage Image"
          category="s3_image"
          opportunity={opportunity}
          fetchOpportunity={fetchOpportunity}
          description="A wider image shown in the scroller on the main landing page"
          ratio="500:274"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ marginTop: 10 }}>
        <OpportunityImageDropZone
          title="Thumbnail Image"
          category="thumbnail_image"
          opportunity={opportunity}
          fetchOpportunity={fetchOpportunity}
          description="A small square image for the gallery list of many opportunities"
          ratio="300:225"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ marginTop: 10 }}>
        <OpportunityImageDropZone
          title="Hero Image"
          category="hero_image"
          opportunity={opportunity}
          fetchOpportunity={fetchOpportunity}
          description="The large image shown at the top of the opportunity detail page"
          ratio="Nearly square"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ marginTop: 10 }}>
        <OpportunityImageDropZone
          title="Application Process Image"
          category="content_image"
          opportunity={opportunity}
          fetchOpportunity={fetchOpportunity}
          description="The second large image on the detail page, near the 'Apply Now' button"
          ratio="Nearly square"
        />
      </Grid>
    </Grid>
  );
};

export default OpportunityImages;
